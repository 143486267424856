import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useMemo } from "react";
import Icon from "components/Icon/Icon";
import fr from "apexcharts/dist/locales/fr.json";

export default function PostStatistics({
  data,
  isDetail,
}: {
  data: any;
  isDetail: boolean;
}) {
  const { t } = useTranslation();

  const postStats = useMemo(
    () => [
      {
        name: t("stats.nbNotifications"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.numberOfPushSent ? data.numberOfPushSent : 0,
      },
      {
        name: t("stats.nbEmails", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.numberOfEmailsSent ? data.numberOfEmailsSent : 0,
      },
      {
        name: t("stats.nbLike"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbLikes ? data.nbLikes : 0,
      },
    ],
    [data, t]
  );

  const newPostsData = {
    labels: data?.newPosts?.map((post: any) => post.date),
    datasets: [
      {
        label: t("stats.newPost"),
        data: data?.newPosts?.map((post: any) => post.value),
        borderWidth: 1,
      },
    ],
  };

  const numberOfNotificationsSentByPostData = {
    labels: data?.numberOfNotificationsSentByPost?.map(
      (post: any) => post.label
    ),
    datasets: [
      {
        name: t("stats.emails"),
        data: data?.numberOfNotificationsSentByPost?.map(
          (post: any) => post.emails
        ),
      },
      {
        name: t("stats.pushNotifications"),
        data: data?.numberOfNotificationsSentByPost?.map(
          (post: any) => post.push
        ),
      },
    ],
  };

  const numberOThreadByPostData = {
    datasets: data?.numberOfThreads?.map(
      (thread: { postName: string; data: any[]; total: number }) => ({
        name: thread.postName,
        data: thread?.data?.map((d) => ({
          x: d.date,
          y: d.value,
        })),
        borderWidth: 1,
      })
    ),
  };

  return (
    <div>
      <dl className="mt-3 grid md:grid-flow-col gap-3 mb-3">
        {postStats.map((item) => (
          <div
            key={item?.name}
            className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                {item?.icon}
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                {item?.name}
              </p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">
                {item?.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
      <div className="w-full h-full">
        {!isDetail ? (
          <div className="h-80 justify-center white-box mb-3">
            <Chart
              options={{
                chart: {
                  id: "newPostsByDate",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: t("stats.nbPosts"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                  categories: newPostsData.labels,
                },
                yaxis: {
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={[
                {
                  name: t("stats.newPostsByDate"),
                  data: newPostsData.datasets[0].data,
                },
              ]}
              height="100%"
              type="line"
            />
          </div>
        ) : (
          <>
            <div className="h-80 justify-center white-box mb-3">
              <Chart
                options={{
                  chart: {
                    id: "nbNotificationsByPost",
                    locales: [fr],
                    defaultLocale: "fr",
                  },
                  stroke: {
                    curve: "straight",
                  },
                  title: {
                    text: t("stats.nbNotificationsByPost"),
                    align: "center",
                    margin: 18,
                    style: {
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#263238",
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                  xaxis: {
                    categories: numberOfNotificationsSentByPostData.labels,
                  },
                  yaxis: {
                    labels: {
                      formatter: (value) => Math.round(value).toString(),
                    },
                  },
                  noData: {
                    text: "Aucune données à afficher",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "18px",
                      color: "#263238",
                    },
                  },
                }}
                series={numberOfNotificationsSentByPostData.datasets}
                height="100%"
                type="bar"
              />
            </div>
            <div className="h-80 justify-center white-box mb-3">
              <Chart
                options={{
                  chart: {
                    id: "threadsByPost",
                    locales: [fr],
                    defaultLocale: "fr",
                  },
                  stroke: {
                    curve: "straight",
                  },
                  title: {
                    text: t("stats.threadsByPost"),
                    align: "center",
                    margin: 18,
                    style: {
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#263238",
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                  xaxis: {
                    type: "datetime",
                  },
                  yaxis: {
                    labels: {
                      formatter: (value) => Math.round(value).toString(),
                    },
                  },
                  noData: {
                    text: "Aucune données à afficher",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "18px",
                      color: "#263238",
                    },
                  },
                }}
                series={numberOThreadByPostData.datasets}
                height="100%"
                type="bar"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
