import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useMemo } from "react";
import fr from "apexcharts/dist/locales/fr.json";

import Icon from "components/Icon/Icon";

export default function SiteStatistics({
  data,
  isDetail,
}: {
  data: any;
  isDetail: boolean;
}) {
  const { t } = useTranslation();

  const genericStats = useMemo(
    () => [
      {
        name: t("stats.nbSite", { wording: getWording(true) }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data?.totalSites || 0,
      },
      {
        name: t("stats.activeSites", { wording: getWording(true, true) }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data?.activeSites || 0,
      },
    ],
    [data, t]
  );

  const siteDetailStats = useMemo(
    () => [
      {
        name: t("stats.nbThreads"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalThreads ? data.totalThreads : 0,
      },
      {
        name: t("stats.nbPosts", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalPosts ? data.totalPosts : 0,
      },
      {
        name: t("stats.nbSubscribers"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.totalFollowers ? data.totalFollowers : 0,
      },
    ],
    [data, t]
  );

  const siteTypeData = {
    labels: Object.keys(data?.sitesByType || {})?.map((type) =>
      type ? t(`sites.type_${type}`) : "Inconnu"
    ),
    datasets: [
      {
        label: t("stats.siteTypes", { wording: getWording(true, false) }),
        data: Object.values(data?.sitesByType || {}),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const siteThreadsByType = {
    datasets:
      data && data.threadsPerType && data.threadsPerType.length > 0
        ? data?.threadsPerType?.map(
            (thread: { type: string; data: any[] }) => ({
              name: thread.type,
              data: thread?.data?.map((d) => ({
                x: d.date,
                y: d.value,
              })),
              borderWidth: 1,
            })
          )
        : [],
  };

  const postsDataset = {
    labels: data.postsPerSite?.map(
      (post: { date: string; value: number }) => post.date
    ),
    datasets: [
      {
        name: t("stats.newPostsByDate"),
        data:
          data.postsPerSite?.map(
            (post: { date: string; value: number }) => post.value
          ) ?? [],
      },
    ],
  };

  const newSitesByDateData = {
    labels: data?.newSitesByDate?.map(
      (newSite: { date: string; value: number }) => newSite.date
    ),
    datasets: [
      {
        label: t("stats.newSitesByDate", {
          wording: getWording(true, false),
        }),
        data: data?.newSitesByDate?.map(
          (newSite: { date: string; value: number }) => newSite.value
        ),
        borderWidth: 1,
      },
    ],
  };

  // TODO
  // const topActiveSites = data?.topActiveSites?.slice(0, 3) || [];

  return (
    <div className="h-5/6">
      {isDetail ? (
        <dl className="mt-3 grid grid-cols-1 xl:grid-cols-3 gap-3 mb-3">
          {siteDetailStats.map((item) => (
            <div
              key={item?.name}
              className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-indigo-500 rounded-md p-3">
                  {item?.icon}
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                  {item?.name}
                </p>
              </dt>
              <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                <p className="text-2xl font-semibold text-gray-900">
                  {item?.stat}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      ) : (
        <dl className="mt-3 grid grid-cols-1 xl:grid-cols-2 gap-3 mb-3">
          {genericStats.map((item) => (
            <div
              key={item?.name}
              className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-indigo-500 rounded-md p-3">
                  {item?.icon}
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                  {item?.name}
                </p>
              </dt>
              <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                <p className="text-2xl font-semibold text-gray-900">
                  {item?.stat}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      )}

      {isDetail ? (
        <div className="w-full h-5/6 grid grid-cols-1 xl:grid-cols-2 gap-3">
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "threadsByType",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                title: {
                  text: t("stats.threadsByType"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                stroke: {
                  curve: "smooth",
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={siteThreadsByType.datasets}
              type="line"
              height="100%"
              width="100%"
            />
          </div>
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "sitePosts",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                title: {
                  text: t("stats.newPostsByDate"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                stroke: {
                  curve: "straight",
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                  categories: postsDataset.labels,
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
                // labels: posts.labels,
              }}
              series={postsDataset.datasets}
              type="bar"
              height="100%"
            />
          </div>
        </div>
      ) : (
        <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-3">
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "newSitesByDate",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: t("stats.newSitesByDate", {
                    wording: getWording(true, false),
                  }),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                  categories: newSitesByDateData.labels,
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={[
                {
                  name: t("stats.newSitesByDate", {
                    wording: getWording(true, false),
                  }),
                  data: newSitesByDateData.datasets[0].data,
                },
              ]}
              height="100%"
              type="line"
            />
          </div>
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "siteTypes",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                title: {
                  text: t("stats.siteTypes", {
                    wording: getWording(true, false),
                  }),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                legend: {
                  position: "bottom",
                },
                labels: siteTypeData.labels,
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={
                data && data.sitesByType
                  ? (Object.values(data.sitesByType || {}) as number[])
                  : []
              }
              height="100%"
              type="pie"
            />
          </div>
        </div>
      )}
    </div>
  );
}
