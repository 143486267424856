import DebounceInput from "../DebounceInput/DebounceInput";
import StickyWrapper from "../StickyWrapper/StickyWrapper";

type FiltersHeaderProps = {
  columns: any[];
  filters: { [key: string]: any };
  handleChangeFilter: (filter: any, value: any) => void;
};

function Filters({ columns, filters, handleChangeFilter }: FiltersHeaderProps) {
  return (
    <StickyWrapper isFilters disabledOnMobile>
      <div className="flex flex-col md:flex-row flex-wrap gap-3 md:gap-1 px-4 py-3 border border-t-0 border-slate-150 rounded-b-lg mb-2 z-10 bg-slate-100">
        {columns?.map((column: any, key: any) =>
          column.hidden ? null : (
            <div
              key={`column-${key}`}
              className={`py-0 flex flex-col ${column.columnClasses} maxLg:w-full mx-2`}
            >
              {column.filterName &&
                (column.filterComponent ? (
                  <column.filterComponent
                    placeholder={column.Header}
                    value={filters[column.filterName]}
                    data-cy={column.filterName}
                    onChange={(value: any) => {
                      handleChangeFilter(column.filterName, value);
                    }}
                  />
                ) : (
                  <DebounceInput
                    className="text-input-filter"
                    placeholder={column.Header}
                    value={filters[column.filterName]}
                    onChange={(value) =>
                      handleChangeFilter(column.filterName, value)
                    }
                  />
                ))}
            </div>
          )
        )}
      </div>
    </StickyWrapper>
  );
}

export default Filters;
